<template>
  <div class="trackingConsentModal">
    <div class="trackingConsentModal__title">
      {{ $t('trackingConsentModal.title') }}
    </div>

    <p
      class="trackingConsentModal__body"
      v-html-safe="$t('trackingConsentModal.body')"
    />

    <div class="trackingConsentModal__buttons">
      <base-button
        modifiers="primary"
        v-on:click="onPrimaryButtonClick"
      >
        {{ primaryButtonLabel }}
      </base-button>

      <base-button
        modifiers="link"
        v-on:click="onSecondaryButtonClick"
      >
        {{ secondaryButtonLabel }}
      </base-button>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import BaseButton from '@/components/BaseButton';

export default {
  components: {
    BaseButton,
  },

  props: {
    enableConsent: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    primaryButtonLabel() {
      if (this.enableConsent) {
        return this.$t('trackingConsentModal.enablePrimaryButtonLabel');
      }

      return this.$t('trackingConsentModal.disablePrimaryButtonLabel');
    },

    secondaryButtonLabel() {
      if (this.enableConsent) {
        return this.$t('trackingConsentModal.enableSecondaryButtonLabel');
      }

      return this.$t('trackingConsentModal.disableSecondaryButtonLabel');
    },
  },

  methods: {
    ...mapActions('member', ['updatePrivacySettings']),

    async onPrimaryButtonClick() {
      try {
        await this.updatePrivacySettings({ tracking_consent: this.enableConsent });
      } catch(error) {
        //
      }

      this.$emit('close');
    },

    async onSecondaryButtonClick() {
      if (this.enableConsent) {
        try {
          await this.updatePrivacySettings({ tracking_consent: !this.enableConsent });
        } catch(error) {
          //
        }
      }

      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.trackingConsentModal__title {
  @include desktop {
    @include heading-2;
    margin: 0 0 rem(8px) 0;
    text-align: center;
  }
}

.trackingConsentModal__body {
  @include small;
  margin: 0 0 rem(32px) 0;
}

.trackingConsentModal__buttons {
  text-align: right;
}
</style>